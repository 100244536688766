import { useEffect } from 'react';
import OneSignal from 'react-onesignal';
import { useAppContext } from './AppContext'; // Załóżmy, że masz stworzony hook useAppContext

function useOneSignal() {
  const { setTokenVariable } = useAppContext();
  console.log('useOneSignal');

  useEffect(() => {
    console.log('useEffect useOneSignal');
    async function initializeOneSignal() {
      console.log('INIT useEffect useOneSignal');

      await OneSignal.init({
        appId: 'f0ad86e7-2fab-4e45-a4f5-a0e0f7328b04',
        allowLocalhostAsSecureOrigin: true
      });

      const token = await OneSignal.getUserId();
      console.log('OneSignal User ID:', token);

      if (!token) {
        OneSignal.showNativePrompt();
        OneSignal.showSlidedownPrompt().then(async () => {
          const newToken = await OneSignal.getUserId();
          console.log('New OneSignal User ID:', newToken);
          setTokenVariable(newToken);
        });
      } else {
        setTokenVariable(token);
      }


    }

    initializeOneSignal();

  }, [setTokenVariable]); // Używamy setTokenVariable jako zależności, aby uniknąć ostrzeżeń ESLint'a

  return null; // Możesz zwrócić coś innego, jeśli jest to wymagane w twoim przypadku
}

export default useOneSignal;