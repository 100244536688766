import React, { useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useNavigate, Link } from 'react-router-dom';
import Container from '@mui/material/Container';






export const InfoRegUser = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const navigate = useNavigate();



  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'name') {
      setName(value);
      setNameError(false);
    } else if (name === 'email') {
      setEmail(value);
      setEmailError(false);
    } else if (name === 'password') {
      setPassword(value);
      setPasswordError(false);
    } else if (name === 'phoneNumber') {
      const formattedPhoneNumber = value.startsWith('+48') ? value : '+48' + value;
      setPhoneNumber(formattedPhoneNumber);
      setPhoneNumberError(false);
    }
  };


  

  const handleNext = async () => {

    

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    if (!emailRegex.test(email) || email.indexOf(`@`) === -1) {
      setEmailError(true);
      return;
    }

  
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).+$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(true);
      return;
    }

    const phoneRegex = /^\+48\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError(true);
      return;
    }

    try {
      const requestData = {
        phone: phoneNumber,
        name,
        email,
        order_id: "373aabe4-6b8c-42d2-8552-1978edc5eb0c",
        password: password
      };

      console.log('Sending registration request data:', requestData);

      const registrationResponse = await axios.post(process.env.REACT_APP_API_URL + '/loyalty/register-user', requestData);

      console.log('Response from server after registration:', registrationResponse.data);

      const authCode = "256302";

      console.log('Received auth_code:', authCode);

      const activationRequestData = {
        phone: phoneNumber,
        auth_code: authCode,
      };

      console.log('Sending activation request data:', activationRequestData);

      


    } catch (error) {
      console.error('Error:', error);
   
      return;
    }

    try {
      navigate(`/accept/${phoneNumber}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ minHeight: '50px' }}>
    
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            name="name"
            label="Imię i nazwisko"
            variant="outlined"
            value={name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={nameError}
            
          />

          <TextField
            name="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={emailError}
          />

          <TextField
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={passwordError}
          />

          <TextField
            name="phoneNumber"
            label="Numer telefonu"
            variant="outlined"
            value={phoneNumber}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={phoneNumberError}
          />

          <Grid container justifyContent="center">
            <Button
              onClick={handleNext}
              variant="contained"
              style={{
                backgroundColor: '#3FC500',
                borderRadius: '15px',
                width: '164px',
                color: '#fff',
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '700',
              }}
            >
              Dalej
            </Button>
          </Grid>
          <Link to="/login"> 
          <button style={{border: "none", color: "#00A3FF", backgroundColor: "#0000", marginTop: "15px", fontFamily: "Poppins" }}>Jeżeli masz konto ,to naciśnij tu</button>
          </Link>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>
    </Container>
  );
};

export const InputPhoneNumber = () => {
  return (
    <>
      <InfoRegUser></InfoRegUser>
    </>
  );
};





   // const allowedDomain = 'mail.ru, gmail.com';