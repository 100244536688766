
import React from 'react';
import { AcceptSMScomponent } from './AcceptSMScomponent';



const AcceptSMSform = () => {

    const containerStyle = {
        backgroundColor: '#00A3FF1F', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', 
        marginTop: '0px', 
    };

    return (
        <div style={containerStyle}>
            <AcceptSMScomponent></AcceptSMScomponent>
        </div>
    )
}

export default AcceptSMSform;









