import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import QRCode from 'qrcode.react';
import { InputPhoneNumber } from '../OrderInfoComponent/InputPhoneNumber';

const DishComponent = (props) => {
    return (
      <Grid item xs={12} sm={12} alignItems="center" key={props.index} // Ustawienie zawartości w pionie na środku ekranu
      justifyContent="center" style={{ marginTop: '5px' }} className={props.item.status === 2 ? 'status-ready' : props.item.status === 4 ? 'status-taken' : 'status-preparing'}>
      <Grid container>
        <Grid item xs={7} sm={7} className='nazwa'>
          {props.item.product.name[0].text}
          {props.item.status === 2 ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M8.44023 11.5949C8.2631 11.5949 8.08597 11.5273 7.95075 11.3921L6.2193 9.66066C5.94887 9.39023 5.94887 8.95213 6.2193 8.6817C6.48974 8.41126 6.92784 8.41126 7.19827 8.6817L8.44023 9.92366L11.7578 6.6068C12.0282 6.33637 12.4663 6.33637 12.7367 6.6068C13.0072 6.87723 13.0072 7.31533 12.7367 7.58577L8.92972 11.3921C8.7945 11.5273 8.61737 11.5949 8.44023 11.5949Z" fill="#2E9100" />
            <path d="M9 18C4.03722 18 0 13.9624 0 9C0 4.03756 4.03722 0 9 0C13.9628 0 18 4.03756 18 9C18 13.9624 13.9628 18 9 18ZM9 1.38462C4.80086 1.38462 1.38462 4.80086 1.38462 9C1.38462 13.1991 4.80086 16.6154 9 16.6154C13.1991 16.6154 16.6154 13.1991 16.6154 9C16.6154 4.80086 13.1991 1.38462 9 1.38462Z" fill="#2E9100" />
          </svg> : props.item.status === 4 ? null : <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.93188 0C7.30844 0 7.6137 0.305261 7.6137 0.681819V2.95455C7.6137 3.33111 7.30844 3.63637 6.93188 3.63637C6.55532 3.63637 6.25006 3.33111 6.25006 2.95455V0.681819C6.25006 0.305261 6.55532 0 6.93188 0Z" fill="#FF7A00" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.20459 0C9.58114 0 9.8864 0.305261 9.8864 0.681819V2.95455C9.8864 3.33111 9.58114 3.63637 9.20459 3.63637C8.82803 3.63637 8.52277 3.33111 8.52277 2.95455V0.681819C8.52277 0.305261 8.82803 0 9.20459 0Z" fill="#FF7A00" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4774 0C11.8539 0 12.1592 0.305261 12.1592 0.681819V2.95455C12.1592 3.33111 11.8539 3.63637 11.4774 3.63637C11.1008 3.63637 10.7955 3.33111 10.7955 2.95455V0.681819C10.7955 0.305261 11.1008 0 11.4774 0Z" fill="#FF7A00" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.63642 5.90911V12.6137L3.63641 12.6165C3.63585 12.7506 3.66185 12.8835 3.7129 13.0075C3.76396 13.1315 3.83906 13.2441 3.93387 13.3389C4.02869 13.4338 4.14134 13.5089 4.26533 13.5599C4.38933 13.611 4.5222 13.637 4.65629 13.6364L4.65915 13.6364L13.7501 13.6364L13.7529 13.6364C13.887 13.637 14.0199 13.611 14.1439 13.5599C14.2679 13.5089 14.3805 13.4338 14.4753 13.3389C14.5702 13.2441 14.6453 13.1315 14.6963 13.0075C14.7474 12.8835 14.7734 12.7506 14.7728 12.6165L14.7728 12.6137V5.90911H3.63642ZM2.6389 4.91159C2.87332 4.67717 3.19126 4.54547 3.52278 4.54547H14.8864C15.218 4.54547 15.5359 4.67717 15.7703 4.91159C16.0047 5.14601 16.1364 5.46395 16.1364 5.79547V12.6124C16.1375 12.926 16.0766 13.2367 15.9572 13.5267C15.8376 13.8171 15.6617 14.081 15.4396 14.3032C15.2175 14.5253 14.9535 14.7012 14.6631 14.8208C14.3731 14.9402 14.0624 15.0011 13.7488 15H4.66043C4.34684 15.0011 4.03612 14.9402 3.74614 14.8208C3.45567 14.7012 3.19176 14.5253 2.96964 14.3032C2.74751 14.081 2.57158 13.8171 2.45197 13.5267C2.33257 13.2367 2.27167 12.926 2.27278 12.6124V5.79547C2.27278 5.46395 2.40448 5.14601 2.6389 4.91159Z" fill="#FF7A00" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.2728 5.95456C18.4987 6.25581 18.4377 6.68317 18.1364 6.90911L15.8637 8.61365C15.5625 8.83959 15.1351 8.77854 14.9092 8.47729C14.6832 8.17604 14.7443 7.74868 15.0455 7.52274L17.3183 5.8182C17.6195 5.59226 18.0469 5.65331 18.2728 5.95456Z" fill="#FF7A00" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.136404 5.95456C0.362339 5.65331 0.789704 5.59226 1.09095 5.8182L3.36368 7.52274C3.66493 7.74868 3.72598 8.17604 3.50004 8.47729C3.27411 8.77854 2.84674 8.83959 2.5455 8.61365L0.272768 6.90911C-0.0284789 6.68317 -0.089531 6.25581 0.136404 5.95456Z" fill="#FF7A00" />
          </svg>}<br />
          {props.item.product.kds_devices ?
            props.item.product.kds_devices.length > 0 && props.item.status===2 ? <div className='kds'><span>Do odbioru w: </span><span className='kds-name'>{props.item.product.kds_devices[0]}</span></div> : null : null}
        </Grid>
        <Grid item xs={2} sm={2} textAlign='right' className='element-product-other'>
          {props.item.count}<span className='szt'> szt</span>
        </Grid>
        <Grid item xs={3} sm={3} textAlign='right' style={{ paddingRight: '10px' }} className='element-product-other'>
          {props.item.sum}<span className='szt'> zł</span>
        </Grid>
      </Grid>
    
    </Grid>



  );
};

export default DishComponent;
