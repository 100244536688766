import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import './App.css';
import { useEffect} from 'react';
import OrderInfoComponent from './components/OrderInfoComponent/OrderInfoComponent';
import StartComponent from './components/StartComponent/StartComponent';
import {Routes } from 'react-router-dom';
import "@fontsource/poppins";
import RegisterFormComponent from './components/RegisterFormComponent/RegisterFormComponent'


import QRScannerComponent from './components/QRScannerComponent/QRScannerComponent';
import OrderFormComponent from './components/OrderFormComponent/OrderFormComponent';
import { useAppContext } from './AppContext'; 
import NavBar from './components/NavBar/NavBar';

import RegulaminPage from './components/RegulaminComponent/RegulaminPage';

import AcceptSMSform from './components/AcceptSMS/AcceptSMSform';
import { JakDodac } from './components/JakDodac/JakDodac';
import Products from './components/ProductComponent/Products';
import Menu from './components/MenuProductComponent/Menu'
import InfoRegUser from './components/InfoRegUser/InfoRegUser';
import History from './components/HistoryOrderComponent/History';


function  App() {
  const { setTokenVariable } = useAppContext();
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL);

    async function initializeOneSignal() {
      console.log('INIT useEffect useOneSignal');

      await OneSignal.init({
        appId: 'f0ad86e7-2fab-4e45-a4f5-a0e0f7328b04',
        allowLocalhostAsSecureOrigin: true
      });

      const token = await OneSignal.getUserId();
      console.log('OneSignal User ID:', token);

      if (!token) {
        OneSignal.showNativePrompt();
        OneSignal.showSlidedownPrompt().then(async () => {
          const newToken = await OneSignal.getUserId();
          console.log('New OneSignal User ID:', newToken);
          setTokenVariable(newToken);
        });
      } else {
        setTokenVariable(token);
      }


    }

    initializeOneSignal();
   
  }, [OneSignal, setTokenVariable]);

  
return (
  <BrowserRouter>
    <div>

    
    <Routes>
      <Route path="/" element={<StartComponent />} />
      <Route path="/register" element={<RegisterFormComponent/>}/>
      <Route path="/form" element={<OrderFormComponent />} />
      <Route path="/scanner" element={<QRScannerComponent />} />
      <Route path="/:id" element={<OrderInfoComponent />} />
      <Route path="/login" element={<InfoRegUser />} />
      <Route path="/regulamin" element={<RegulaminPage/>}/>
      <Route path="/accept/:phone" element={<AcceptSMSform/>}/>
      <Route path="/jak" element={<JakDodac/>}/>
      <Route path="/restauracji" element={<Products/>}/>
      <Route path="/menu" element={<Menu/>}/>
      <Route path="/history" element={<History/>}/>
    </Routes>
  
  </div>
  </BrowserRouter>
);

}

export default App;
