import React, { useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Klodka } from './Klodka';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const loginData = {
        username: phoneNumber,
        password: password,
      };

      console.log('Sending login request data:', loginData);

      const loginResponse = await axios.post(process.env.REACT_APP_API_URL+'/loyalty/login', loginData);

      console.log('Response from server after login:', loginResponse.data);

      if (loginResponse.data.success || loginResponse.data.token) {

        localStorage.setItem('token', loginResponse.data.token);
        navigate('/restauracji');
      } else {
        console.error('Login failed:', loginResponse.data || 'No response data provided.');
      }
    } catch (error) {
      console.error('Error during login:', error.message);
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    background: "#00A3FF1F",
    marginTop: "-50px"
  };

  const textFieldStyle = {
    borderRadius: "20px",
    width: "70%",
    margin: "10px",
  };

  const buttonStyle = {
    borderRadius: "15px",
    background: "#3FC500",
    color: "white",
    width: "150px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "20px",
  };

  return (
    <div style={containerStyle}>
      <Klodka />
      <TextField
        label="Numer telefonu"
        variant="outlined"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        style={textFieldStyle}
      />
      <TextField
        label="Hasło"
        type="password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={textFieldStyle}
      />
      <Button variant="contained" onClick={handleLogin} style={buttonStyle}>
        Zaloguj się
      </Button>
    </div>
  );
};

export default Login;
