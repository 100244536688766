
import React from "react";
import { HistoryOrderComponent } from './HistoryOrderComponent';


const History = () => {
    



  return (
    <div >
      <HistoryOrderComponent></HistoryOrderComponent>

    </div>
  );
};




export default History;

