import { red } from '@mui/material/colors';
import { lineHeight } from '@mui/system';
import React from 'react';
import { RegulaminComponent } from "./RegulaminComponent";





const RegulaminPage = () => {

  const containerStyle = {
    backgroundColor: '#00A3FF1F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    minHeight: '100vh', 
    marginTop: '0px', 
  };


  

  

  return(
    
    <div style={containerStyle}>
      <RegulaminComponent></RegulaminComponent>
     
    </div>
  )
}




export default RegulaminPage;