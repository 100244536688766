import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './TicketComponent.css';

import QRCode from 'qrcode.react';
import { toPng } from 'html-to-image';
import { FaSms } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

const shareIconStyle = {
  fontSize: '24px',   // Rozmiar ikony
  color: 'blue',      // Kolor ikony
  // Inne właściwości CSS według potrzeb
};

const TicketComponent = (props) => {
  const qrCodeRef = useRef(null);

  const handleSMSShare = () => {
    if (qrCodeRef.current) {
      console.log(props);
      const smsText = `Oto twoje bilety: <a href="https://punkty.wciagnij.to/${props.order}">Bilety</a>`;
      window.location.href = `sms:&body=${encodeURIComponent(`${smsText}`)}`;
    }
  }

  const handleEmailShare = () => {
    if (qrCodeRef.current) {
      console.log(props);
      const emailSubject = 'Bilety na lodowisko';
      const emailBody = `Oto twoje bilety: <a href="https://punkty.wciagnij.to/${props.order}">Bilety</a>`;
      // Wyślij SMS z kodem QR jako obrazkiem
      window.location.href = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;



    }
  };

  return (
    props.item.tickets?.map((ticket, indexT) => (
      <Grid container justifyContent="flex-end" paddingBottom={2} key={indexT}>
        <Grid item xs={12} sm={12} className='element-product-name'>
          <div className='flex-container'>
            <span className='nazwa'>
              {props.item.product.name[0].text}
            </span>
            <div ref={qrCodeRef} className='kod'>
              <QRCode value={ticket} />
            </div>
          </div>
        </Grid>
      </Grid>
    ))
  );
  
  
};

export default TicketComponent;
