import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AcceptStyle.css'

export const AcceptSMScomponent = () => {
  const navigate = useNavigate();
  const [activationStatus, setActivationStatus] = useState('');
  const [authCode, setAuthCode] = useState(Array(6).fill(""));

  const handleInputChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newAuthCode = [...authCode];
      newAuthCode[index] = value;
      setAuthCode(newAuthCode);

      if (index < newAuthCode.length - 1 && value !== "") {
    
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    }
  };






  const handleActivation = async () => {
    try {
      const phone = window.location.pathname.split('/accept/')[1];
      const requestData = {
        phone: `+${phone}`,
        auth_code: authCode.join(''),
      };

      const response = await axios.post(process.env.REACT_APP_API_URL+'/loyalty/activate-user', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status === 1) {
        console.log('Sukces:', response.data);
        setActivationStatus('Sukces.');
        navigate('/login');
      } else {
        console.error('Error:', response.data);
        setActivationStatus('Wpowadzony kod jest błędny');
      }
    } catch (error) {
      console.error('Ошибка при активации пользователя:', error);
      setActivationStatus('Ошибка при активации пользователя.');
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "160px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
  <path d="M38.0037 9.49051H35.6241V9.1788C35.6241 8.12121 35.6264 7.06361 35.6241 6.00602C35.6191 3.95318 34.0761 2.38517 32.0222 2.3835C23.3483 2.37682 14.6738 2.39073 5.9999 2.37236C4.05004 2.36847 2.36235 3.97267 2.36791 6.09564C2.39463 16.922 2.38071 27.7484 2.38071 38.5748C2.38071 42.7122 2.37515 46.8502 2.3835 50.9876C2.38684 52.5673 3.14496 53.6789 4.56603 54.3429C5.04473 54.5667 5.55905 54.6218 6.08506 54.6212C12.1618 54.6185 18.2379 54.619 24.3146 54.619C26.8751 54.619 29.4356 54.624 31.996 54.6173C33.7489 54.6129 35.1644 53.493 35.5367 51.8181C35.6007 51.5314 35.6202 51.2303 35.6213 50.9353C35.6286 49.1079 35.6252 47.2799 35.6252 45.4525C35.6252 45.3534 35.6252 45.2538 35.6252 45.1413H37.9903C37.9954 45.2438 38.0043 45.3412 38.0043 45.438C38.0048 47.2749 38.0098 49.1118 38.0037 50.9486C37.9942 53.9678 36.0032 56.3624 33.0397 56.9106C32.6868 56.9758 32.3205 56.9941 31.9604 56.9947C23.3143 56.9992 14.6677 56.9997 6.02161 56.9969C3.02473 56.9958 0.607858 54.968 0.0807324 52.0107C0.0211733 51.675 0.00391791 51.3277 0.00391791 50.9859C2.1526e-05 35.9954 -0.00109173 21.0038 0.00113478 6.01215C0.00169141 3.01917 2.04173 0.596169 4.99575 0.0785059C5.33139 0.0195035 5.67873 0.00391791 6.0205 0.00391791C14.6666 2.1526e-05 23.3127 -0.00109173 31.9593 0.00113478C35.023 0.00169141 37.4571 2.08069 37.9375 5.1054C37.9865 5.41544 37.9993 5.73383 38.0015 6.04833C38.0093 7.09646 38.0043 8.14514 38.0043 9.19327C38.0043 9.284 38.0043 9.37417 38.0043 9.49051H38.0037Z" fill="#00A3FF"/>
  <path d="M26.1197 32.4547C26.1197 27.9933 26.1152 23.532 26.1219 19.0706C26.1253 16.8096 27.5691 14.9349 29.72 14.3989C30.1024 14.3037 30.5076 14.2569 30.9022 14.2564C38.007 14.248 45.1118 14.2475 52.216 14.2519C54.9017 14.2536 56.9941 16.3498 56.9969 19.0389C57.0019 24.5295 56.9997 30.0206 56.998 35.5111C56.9974 38.2959 54.9429 40.3616 52.1509 40.3677C48.3297 40.3761 44.5084 40.3699 40.6872 40.3699C39.6207 40.3699 38.5536 40.3638 37.4871 40.3772C37.3257 40.3794 37.1376 40.4367 37.0095 40.5325C34.0817 42.7172 31.1594 44.9092 28.241 47.1057C28.0896 47.2198 27.9772 47.2504 27.8007 47.1552C27.3276 46.9008 26.8466 46.6609 26.3602 46.4338C26.1737 46.347 26.1147 46.2379 26.1152 46.0331C26.1219 41.5066 26.1202 36.9806 26.1202 32.4542L26.1197 32.4547ZM28.4993 43.9296C28.6245 43.8388 28.6997 43.7865 28.7731 43.732C31.1393 41.958 33.505 40.1829 35.8723 38.4101C36.2308 38.1412 36.6372 38.0054 37.0864 38.0054C42.086 38.0054 47.085 38.0071 52.0847 38.0037C53.6265 38.0026 54.6173 37.0185 54.6179 35.4883C54.6206 30.0345 54.6206 24.5807 54.6179 19.1268C54.6173 17.6212 53.6304 16.6332 52.1281 16.6326C45.079 16.6304 38.0293 16.6304 30.9802 16.6326C29.5057 16.6326 28.5004 17.6423 28.4998 19.1157C28.4993 27.2781 28.4998 35.4405 28.4998 43.6028V43.9312L28.4993 43.9296Z" fill="#00A3FF"/>
  <path d="M23.7537 7.14487C23.7537 7.88852 23.7565 8.61937 23.7476 9.34967C23.747 9.40143 23.663 9.4621 23.6051 9.49995C23.5728 9.52111 23.5149 9.50496 23.4687 9.50496C20.4824 9.50496 17.4966 9.50496 14.5103 9.50496C14.4374 9.50496 14.3645 9.50496 14.271 9.50496V7.14487H23.7537Z" fill="#00A3FF"/>
  <path d="M33.327 28.4999C34.0633 28.4999 34.6601 27.903 34.6601 27.1667C34.6601 26.4305 34.0633 25.8336 33.327 25.8336C32.5908 25.8336 31.9939 26.4305 31.9939 27.1667C31.9939 27.903 32.5908 28.4999 33.327 28.4999Z" fill="#00A3FF"/>
  <path d="M36.6349 28.4999C37.3711 28.4999 37.968 27.903 37.968 27.1667C37.968 26.4305 37.3711 25.8336 36.6349 25.8336C35.8986 25.8336 35.3018 26.4305 35.3018 27.1667C35.3018 27.903 35.8986 28.4999 36.6349 28.4999Z" fill="#00A3FF"/>
  <path d="M39.92 28.4999C40.6563 28.4999 41.2532 27.903 41.2532 27.1667C41.2532 26.4305 40.6563 25.8336 39.92 25.8336C39.1838 25.8336 38.5869 26.4305 38.5869 27.1667C38.5869 27.903 39.1838 28.4999 39.92 28.4999Z" fill="#00A3FF"/>
  <path d="M44.4344 28.4999C45.1707 28.4999 45.7676 27.903 45.7676 27.1667C45.7676 26.4305 45.1707 25.8336 44.4344 25.8336C43.6982 25.8336 43.1013 26.4305 43.1013 27.1667C43.1013 27.903 43.6982 28.4999 44.4344 28.4999Z" fill="#00A3FF"/>
  <path d="M47.7425 28.4999C48.4788 28.4999 49.0757 27.903 49.0757 27.1667C49.0757 26.4305 48.4788 25.8336 47.7425 25.8336C47.0063 25.8336 46.4094 26.4305 46.4094 27.1667C46.4094 27.903 47.0063 28.4999 47.7425 28.4999Z" fill="#00A3FF"/>
  <path d="M51.0277 28.4999C51.764 28.4999 52.3608 27.903 52.3608 27.1667C52.3608 26.4305 51.764 25.8336 51.0277 25.8336C50.2914 25.8336 49.6946 26.4305 49.6946 27.1667C49.6946 27.903 50.2914 28.4999 51.0277 28.4999Z" fill="#00A3FF"/>
</svg>
      
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <p style={{ fontFamily: "Poppins", fontSize: "28px", fontWeight: "600", textAlign: "center" }}>Weryfikacja</p>
        <p style={{ fontFamily: "Poppins", fontSize: "13px", fontWeight: "600", textAlign: "center" }}>Wpisz kod otrzymany za pomocą SMS:</p>
      </div>
      
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px", width: "70%" }}>
        {authCode.map((value, index) => (
          <input
            key={index}
            id={`code-input-${index}`}
            type="text"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            style={{
              width: "30px",
              height: "40px",
              margin: "2px",
              borderRadius: "7px",
              border: `1.5px solid ${authCode.length === 6 ? "rgba(0, 163, 255, 0.43)" : "red"}`,
            }}
            maxLength={1}
            inputMode="numeric"
          />
        ))}
      </div>
      <button onClick={handleActivation} className="knopka">Potwierdż</button>
      <p>{activationStatus}</p>
    </div>
  );
  
};

