import React, { useEffect, useState } from 'react';
import './OrderInfoComponent.css';


import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import DishToOrderComponent from '../DishToOrderComponent/DishToOrderComponent';
import DishComponent from '../ProdInfoComponents/DishComponent';
import TicketComponent from '../ProdInfoComponents/TicketComponent';
import { useAppContext } from '../../AppContext';
import useOneSignal from '../../onesignal';
import QRCode from 'qrcode.react';

const OrderInfoComponent = () => {
  //const [id, setId] = useState(null);
  const { tokenVariable } = useAppContext();

  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [dishToOrder, setDishToOrder] = useState(false);
  const { id } = useParams();





  useEffect(() => {

    const playSound = () => {
      if (audioPlayed < 20) {
        try {
          const audio = new Audio('/audio/ring.mp3'); // Podaj właściwą ścieżkę do pliku dźwiękowego
          audio.play();
          audioIsPlaying = true;
          audioPlayed++;
        } catch (e) { console.log('bład'); }
      } else {
        audioIsPlaying = false;
      }

    };

    // Odczytaj adres URL
    var audioPlayed = 0;
    var audioIsPlaying = false;

    console.log(id);

    if (id) {
      const fetchOrderData = async () => {
        var requestData = { id: id };
        try {
          // Wywołanie API - tu podaj adres URL API oraz id użytkownika
          audioIsPlaying = false;
          const response = await fetch('https://system2.abspos.pl/v2_api/getOrder', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
          });
          const data = await response.json();
          console.log(data.bill.status);
          if (typeof data.bill.status === 'number') {
            switch (data.bill.status) {
              case 1: setOrderStatus('Roboczy'); break;
              case 2: setOrderStatus('Wysłane'); break;
              case 3: setOrderStatus('Zapłacone'); break;
              case 4: setOrderStatus('Otrzymane'); break;
              case 5: setOrderStatus('W przygotowaniu'); break;
              case 6: setOrderStatus('Gotowe'); break;
              case 7: setOrderStatus('W dostawie'); break;
              case 8: setOrderStatus('Dostarczone'); break;
              case 9: setOrderStatus('Płatność rozpoczęta'); break;
              case 10: setOrderStatus('Zakończone'); break;
              case 11: setOrderStatus('Wydane'); break;
              case 0: setOrderStatus('Anulowane'); break;
              default: setOrderStatus('W przygotowaniu'); break;
            }
          }

          setOrder(data);
          var toTaken = false;
          if (data.bill) {
            data.bill.products.map((item, index) => {
              if (item.status === 2) {
                toTaken = true;
              }
              return item;
            });
            if (toTaken) {
              setDishToOrder(true);
              if (!audioIsPlaying) {
                playSound();
              }
            } else {
              setDishToOrder(false);
            }

            const tokenOneSignal = tokenVariable;
            console.log(tokenOneSignal);
            if (tokenOneSignal) {
              var sendToken = false;
              if (!('notify' in data.bill)) {
                sendToken = true;
              } else {
                if (Array.isArray(data.bill.notify)) {
                  if ('fcm' in data.bill.notify) {
                    if (Array.isArray(data.bill.notify.fcm)) {
                      if (data.bill.notify.fcm.includes(tokenOneSignal)) {
                        sendToken = false;
                      } else {
                        sendToken = true;
                      }
                    } else {
                      sendToken = true;
                    }
                  } else {
                    sendToken = true;
                  }
                } else {
                  sendToken = true;
                }
              }


              if (sendToken) {
                const notifyRequest = {
                  fcm: tokenOneSignal,
                  phone: null,
                }
                try {
                  await fetch('https://system2.abspos.pl/v2_api/saveOrderPagingData', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(notifyRequest),
                  });
                } catch (error) {
                  console.error('Wystąpił błąd podczas zapisu tokenu', error);
                }
              }
            }



          }// Ustawienie danych z odpowiedzi w stanie komponentu
        } catch (error) {
          console.error('Wystąpił błąd podczas pobierania danych:', error);
        }
      };

      fetchOrderData();

      const interval = setInterval(() => {
        fetchOrderData();
      }, 10000);

      return () => clearInterval(interval);
    }


  }, [id, tokenVariable]);

  // Reszta komponentu
  // Możesz teraz korzystać z wartości "id" w swoim komponencie
  // np. wyświetlić go w elemencie JSX lub użyć do wykonania odpowiednich akcji
  return (


    <div style={{ width: '100%' }}>
      {order ?

        <Container maxWidth="lg" >
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} textAlign='left'>
              <span className="element-lokal">Lokal:</span>
              {order.local.logo ? <div className="element-nr-zam-num"><img src={'https://haproxy.abspos.pl/media/' + order.local.logo} className='logo' alt={order.local.name}></img></div> : <div className="element-local-name">{order.local.name}</div>}
            </Grid>
            <Grid item xs={6} sm={6} textAlign='right'>
              <span className="element-nr-zam">Numer zamówienia:</span>
              <div className="element-nr-zam-num">{order.bill.table_number}</div>
            </Grid>
          </Grid>
          <Grid container  >
            {order.bill.products.map((item, index) => {
              
              switch (item.product.product_type) {
                case 7:
                  return <TicketComponent item={item} order={order.bill.id} index={index} key={index}/>;
                default:
                  return <DishComponent item={item} order={order.bill.id} index={index}  key={index}/>;
              }
           
            })}
          </Grid>
          <Grid container spacing={1} paddingTop='20px' direction="row">
            <Grid item xs={7} sm={7} >



            </Grid>
            <Grid item xs={5} sm={5} className='element-bill-suma' style={{ display: 'flex' }}>

              <Grid container direction="row" >
                <Grid item xs={3} sm={3} ><span >Suma:</span></Grid>
                <Grid item xs={9} sm={9} textAlign={'right'} style={{ paddingRight: '10px', marginTop: '-6px' }}><div className='element-suma'>{order.bill.sum}<span className='szt'> zł</span></div></Grid>



              </Grid>
            </Grid>
          </Grid>


          {order.local.local_type!=="ICERINK" && 
          <Grid item xs={5} sm={5} >

            <Grid container spacing={1} direction="row" className='legenda-row'>
              <Grid item xs={3} sm={3} className="status-preparing" ></Grid>
              <Grid item xs={9} sm={9} className="legenda">W przygotowaniu</Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className='legenda-row'>
              <Grid item xs={3} sm={3} className="status-ready" ></Grid>
              <Grid item xs={9} sm={9} className="legenda">Do odbioru</Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className='legenda-row'>
              <Grid item xs={3} sm={3} className="status-taken" ></Grid>
              <Grid item xs={9} sm={9} className="legenda">Wydane</Grid>
            </Grid>

          </Grid>
}


          {dishToOrder ? <DishToOrderComponent /> : null}
        </Container>
        : <div></div>}

    </div>



  );
};

export default OrderInfoComponent;
