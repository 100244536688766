import React, { createContext, useState } from 'react';
import { useContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [qrData, setQrData] = useState('');
  const [tokenVariable, setTokenVariable] = useState(''); 

  return (
    <AppContext.Provider value={{ qrData, setQrData,tokenVariable, setTokenVariable}}>
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}