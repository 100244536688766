import React from 'react';

import './RestaurantStyle.css'
import CircularProgress from '@mui/material/CircularProgress';


import { useState,useEffect } from 'react';
import axios from 'axios';


export const RestaurantList = ({ setSelectedRestaurant }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL+'/restaurantsGeo',
          {
            filter: {},
            source: 3,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const processedData = response.data.data.map((restaurant) => {
          if (restaurant.logo) {
            restaurant.logo = `https://haproxy.abspos.pl/media/${restaurant.logo}`;
          }
          return restaurant;
        });

        setRestaurants(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="restaurant-list">
      <div className="search-input">
        <input
          type="text"
          placeholder="Szukaj"
          value={searchText}
          onChange={handleSearchTextChange}
        />
      </div>
      {loading ? (
         <div className="loader-container">
         <CircularProgress style={{ color: '#FF7A00' }} />
       </div>
      ) : (
        <div className="restaurant-grid">
          {restaurants
            .filter((restaurant) =>
              restaurant.name.toLowerCase().includes(searchText.toLowerCase()) 
              &&
              restaurant.loyalty_program === true
            )
            .map((restaurant) => {
              console.log("Name:", restaurant.name);
              return (
                <div
                  key={restaurant.id}
                  className="restaurant-item"
                  onClick={() => setSelectedRestaurant(restaurant)}
                >
                  {restaurant.logo ? (
                    <img src={restaurant.logo} alt={restaurant.name} />
                  ) : (
                    <p>Brak logotypu</p>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};




export const MenuList = ({ selectedRestaurant, goBack }) => {
  const [menuData, setMenuData] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  
  const updateSelectedItems = (updatedItems) => {
    setSelectedItems(updatedItems);
  };

  const clearSelectedItems = () => {
    //setSelectedItems([]);
  };

  const handleItemClick = (product, attribute) => {
    const selectedItem = {
      name: `${product.slug.replaceAll('-', ' ')} ${attribute.name[0]?.text}`,
      price: attribute.loyalty_points_price,
    };
  
    setSelectedItems([...selectedItems, selectedItem]);
  };
  
  const Modal = ({ onClose }) => {
    const removeItem = (index) => {
   
      const updatedItems = [...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)];

      updateSelectedItems(updatedItems);
    };
  
    return (
      <>
        <div className="overlay" style={{ zIndex: 7000, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
        <div className="modal-container" style={{ zIndex: 7000, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#f0f0f0', padding: '30px', borderRadius: '15px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)', width: '70%', maxWidth: '600px' }}>
      


          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
           
            <h2>Twoje zamówienie</h2>
            <button
           onClick={() => { onClose(); clearSelectedItems(); }}
            style={{
                    width: '40px', 
                    height: '40px', 
                    borderRadius: '50%',
                    backgroundColor: '#FF8A001F',
                    borderColor: '#FF8A001F',
                    color: '#FF7A00',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
          >&#10006; 
          </button>
          </div>
  
          {selectedItems.map((item, index) => (
            <div key={index} className="product-in-order">
              <p>
              <button onClick={() => removeItem(index)} className="transparent-button">Usuń</button>
              {item.name} <span className="price-style">Wartość: {item.price}pkt</span>
              </p>
            </div>
          ))}

  
          <p style={{ textAlign: 'center', borderRadius: '55px', background: '#00A3FF', padding: '10px', color: '#fff', fontFamily: 'Poppins', fontWeight: '500' }}>Podsumowanie: {calculateTotal(selectedItems)}pkt</p>
  
        </div>
      </>
    );
  };
    
  const calculateTotal = (selectedItems) => {
    return selectedItems.reduce((total, item) => total + item.price, 0);
  };

  useEffect(() => {
    if (selectedRestaurant) {
      const fetchData = {
        test: true,
        slug: selectedRestaurant.slug,
        debug: true,
        source: 3,
        loyalty_program: true, 
        
        restaurant: {
          
        },
      };
  
      axios
        .post(process.env.REACT_APP_API_URL+'/restaurant', fetchData)
        .then((response) => {
          const apiData = response.data;
          console.log(apiData.category);
  
          const categories = apiData.category;
  
          const categoryNamesAndProductSlugs = categories.map((category) => {
            const productsWithPoints = category.products
  
            return {
              apiData,
              categoryName: category.name[0]?.text,
              productSlugs: productsWithPoints.map((product) => product.slug.toString()),
              
            };
          });
          setApiData(apiData)
          setMenuData(categoryNamesAndProductSlugs);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [selectedRestaurant]);

  return (
    <div>
   
      {selectedRestaurant && (
        <div className="menu-list">
          <button onClick={goBack} style={{ backgroundColor: '#FF7A00', color: 'white', padding: '8px 16px', border: 'none', borderRadius: "20px", width: "20%", marginLeft: "5px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 12" fill="none" transform="rotate(90)">
              <path d="M8.69201 8.27281L5.24247 11.6931C5.05193 11.8977 4.79239 12 4.50657 12C4.22075 12 3.9645 11.8977 3.77067 11.6931L0.307994 8.27281C-0.102665 7.86701 -0.102665 7.20546 0.307994 6.79966C0.718653 6.39386 1.38228 6.39386 1.79294 6.79966L3.44871 8.44331V1.04348C3.44871 0.467178 3.91851 0 4.5 0C5.08149 0 5.55129 0.467178 5.55129 1.04348V8.44331L7.20706 6.79966C7.61772 6.39386 8.28135 6.39386 8.69201 6.79966C9.10266 7.20546 9.10266 7.8636 8.69201 8.27281Z" fill="white"/>
            </svg>
          </button>
          <img src={selectedRestaurant.logo} alt={selectedRestaurant.name} style={{ marginLeft: "20px", width: "40%", height: "100%" }} />
          {selectedItems.length > 0 && (
          <button onClick={() => setIsModalOpen(true)} className="fixed-button">Przejdż do podsumowania</button>
        )}
         {menuData && (
  <div>
    {apiData.category.map((category, index) => {
 
      const hasLoyaltyPointsProducts = category.products.some(product => (
        product.attributes.some(attribute => (
          attribute.hasOwnProperty('loyalty_points_price') && attribute.loyalty_points_price !== 0
        ))
      ));


      if (!hasLoyaltyPointsProducts) {
        return null;
      }

      return (
        <div key={index} className="category">
          <strong className="categoryName">{category.slug}</strong>
          <div className="dania-list">
            {category.products.map((product) => {
   
              const hasLoyaltyPoints = product.attributes.some(attribute => (
                attribute.hasOwnProperty('loyalty_points_price') && attribute.loyalty_points_price !== 0
              ));

             
              if (!hasLoyaltyPoints) {
                return null;
              }

              return (
                <div key={product.id} >
                  {product.attributes.map(attribute => {

                    if (attribute.hasOwnProperty('loyalty_points_price') && attribute.loyalty_points_price === 0) {
                  
                      return null;
                    }

                    return (
                      <div key={attribute.id} className='dania'>
                        <p>{product.slug.replaceAll('-', ' ')} {attribute.name[0]?.text}</p>
                        <p className="description">{product.description[0]?.text}</p>

                        {attribute.loyalty_points_price !== 0 && (
                          <button
                            className="buy-for-punkts"
                            onClick={() => handleItemClick(product, attribute)}
                          >
                            Aktywuj za: {attribute.loyalty_points_price}pkt
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      );
    })}
  </div>
)}

 {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}

        </div>
      )}
    </div>
  );
};



export const ProductComponent = () => {
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [setSearchedText] = useState('');

  const handleGoBack = () => {
    setSelectedRestaurant(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL+'/restaurantsGeo',
          {
            filter: {},
            source: 3,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const processedData = response.data.data.map((restaurant) => {
          if (restaurant.logo) {
            restaurant.logo = `https://haproxy.abspos.pl/media/${restaurant.logo}`;
          }
          return restaurant;
        });

        setFilteredRestaurants(processedData);
      } catch (error) {
        console.error('Error fetching', error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (text) => {
    const lowerCaseText = text.toLowerCase();
    const filtered = filteredRestaurants.filter((restaurant) =>
      restaurant.name.toLowerCase().includes(lowerCaseText)
    );

    setSearchedText(text);
    setSelectedRestaurant(null);
    setFilteredRestaurants(filtered);
  };

  return (
    <div>
      <span
        style={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
          marginLeft: '2%',
          marginTop: '20px',
          display: 'center',
          fontFamily: 'Poppins',
          fontSize: '28px',
          
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
        }}
      >
        Program lojalnościowy
      </span>
     
      {selectedRestaurant ? (
        <MenuList selectedRestaurant={selectedRestaurant} goBack={handleGoBack} />
      ) : (
        <RestaurantList setSelectedRestaurant={setSelectedRestaurant} />
      )}
    </div>
  );
};
             

