import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import styles from './HistoryStyle.css';

export const HistoryOrderComponent = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [token, setToken] = useState(null); 

  useEffect(() => {
  
    const storedToken = localStorage.getItem('token'); 
    setToken(storedToken);

  }, []); 

  const fetchOrderHistory = async () => {
    try {
      let isMounted = true;
  

      const storedToken = localStorage.getItem('token');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/loyalty/user-history/${currentPage}`,
        {
          local_id: null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`,  
          },
        }
      );

      console.log(':', response.data);
      setOrderHistory(response.data.data);
      setLoading(false);

      if (isMounted) {
        setOrderHistory(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchOrderHistory();
  },[]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleDetailsClick = (orderId) => {
    setSelectedOrderId((prevOrderId) => (prevOrderId === orderId ? null : orderId));
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Historia zamówień</h1>
      <ul>
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <p key={index}>
              <Skeleton sx={{ width: 367, height: 78 }} />
            </p>
          ))
          ) : (
            Array.isArray(orderHistory) && orderHistory.length > 0 ? (
              orderHistory.map((order) => (
                <div key={order.id} className="obertka">
                  <div className={styles.orderInfo}>
                
  
                    <div style={{ marginLeft: "10px" }}>
                    
           
                  
                      <p>
                      <div style={{ display: "flex", alignItems: "center" }}>

                       <div style={{ color: "black", fontFamily: "Poppins", fontSize: "18px", marginLeft: "0px", fontWeight: "600" }}>
                        {order.localName}
                      </div>
                         <div className="punkty">+{order.points}pkt</div>
                      </div>
                        <span style={{ color: "#AAA", fontFamily: "Poppins", fontSize: "10px" }}>zamówienie:</span>
                        <span style={{ color: "black", fontFamily: "Poppins", fontSize: "18px", marginLeft: "10px", fontWeight: "600" }}>{order.order_id.substring(0, 8)}</span>
                        <span style={{ color: "#AAA", fontFamily: "Poppins", fontSize: "10px", marginLeft: "10px" }}>kwota:</span>
                        <span style={{ color: "#00A3FF", fontFamily: "Poppins", fontSize: "18px", marginLeft: "10px", fontWeight: "600" }}>{order.sum}zł</span>
                        <p style={{color: "black", fontFamily: "Poppins", fontSize: "14px", marginLeft: "0px",}}>Data: {formatDate(order.timestamp)}</p>
                      </p>
                  </div>
                </div>
             
              </div>
            ))
          ) : (
            <p style={{ color: "black", fontFamily: "Poppins", fontSize: "18px",fontWeight: "600", textAlign: "center", marginTop: "300px", marginRight: "35px", whiteSpace: "nowrap"}}>
            Nie robiłeś jeszcze zamówień😊
          </p>
          )
        )}
      </ul>
    </div>
  );
};

export default HistoryOrderComponent;






/* order details


   <button onClick={() => handleDetailsClick(order.id)} className="details">
                  Szczegóły
                </button>
                {selectedOrderId === order.id && (
                  <>
                    
                    
                      {order.products.map((product) => (
                        <p key={product.id} className="obertka-a">
                          <div className="lista-produktow">
                          <span style={{color: "black", fontFamily: "Poppins", fontSize: "18px", marginLeft: "-20px",}}>{product.attribute.name[0].text} </span>
                          <span style={{marginLeft: "160px",color: "black", fontFamily: "Poppins", fontSize: "18px", marginRight: "10px"}}>{product.count}szt</span>
                          </div>
                         
                        </p>
                      ))}
                  
                  </>
                )}
*/




/*'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDE0NDI3NjMsImV4cCI6MTcwNDAzNDc2Mywicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoiKzQ4NTM3NzgyNzAwIn0.RAJ8wfuLjQFfoc9CbOCpLOJ18YoiYh_AEmOOm_HjJJS-UQ4nBR7YdMOG-Ixr1VKHlZUF1vsHPiKRyAaFnyDmNBrHlio1tUkUfcGBto2RYMqi4D5CsKEUQeNJgxx4APcTzmX5owSUO8uuU3G_uR97a_bACkPDeFhDe84uz72RKv9quhOM6MMfqVM6zCJT9ZTrTlCP2-79awaLocMP5B3ZZSRZJaxD59Zq-LY4Cztb8kR_x9YVj2DPndqQzZhrsxFJebjJVUg5D8fM_q3nIZXhWGbfawfBdVR37VEXaYMPqYfyoTcvDliemadWaTTY63BJOBGL5Pt89sO4WKQB6UAOSQ'*/