
import React from "react";
import { ProductComponent } from './ProductComponents';


const Products = () => {
  const containerStyle = {

  };


  return (
    <div style={containerStyle}>
      <ProductComponent></ProductComponent>

    </div>
  );
};




export default Products;

