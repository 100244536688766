
import React from "react";
import { MenuProductComponent } from './MenuProductComponent';
import { Link } from "react-router-dom";





    

const Menu = () => {
  const containerStyle = {

  };


  return (
    <div style={containerStyle}>
      <MenuProductComponent></MenuProductComponent>

    </div>
  );
};




export default Menu;

