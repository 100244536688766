import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

const UwagaComponent = () => {
    return (
        <Card variant="outlined" className='element-card-uwaga'>
            <CardHeader
                avatar={
                    <img src="/img/i-push.png" classNмерame="i-push" alt="Ikona push"></img>
                }
                title="Aby otrzymywać powiadomienia PUSH dodaj skrót aplikacji na pulpit"
            />
            <Link to = "/jak">
            <Button variant="contained" style={{ marginLeft: '16%', borderRadius: '15px',marginBottom:'10px', backgroundColor: '#FF0000', fontFamily: "Poppins",  }}>
                Jak dodać skrót na pulpit?
            </Button>
            </Link>
        </Card>
    );
};

export default UwagaComponent;
