
import { InputPhoneNumber } from './InputPhoneNumber';
import React from 'react';
import {Klodka} from './Klodka';




const RegisterFormComponent = () => {

  const containerStyle = {
    backgroundColor: '#00A3FF1F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    minHeight: '100vh', 
    marginTop: '0px', 
  };


  

  

  return(
    
    <div style={containerStyle}>
      <Klodka></Klodka>

     
    </div>
  )
}




export default RegisterFormComponent;








