import React, { useState } from 'react';
import './DishToOrderComponent.css';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const DishToOrderComponent = () => {
   

    return (
        <Card variant="outlined" className='element-card-uwaga'>
      <CardHeader
        
        avatar={
            <img src="/img/i-push.png" className="i-push"></img>
        }
       
        title="Twoje potrawy są gotowe!"
        
      />
      </Card>
    );
};

export default DishToOrderComponent;