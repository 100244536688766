import React from 'react';

import './MenuStyle.css'
import Grid from '@mui/material/Grid';

import { useState,useEffect } from 'react';
import axios from 'axios';


export const Menu = () => {

  const [logoUrl, setLogoUrl] = useState('');


  useEffect(() => {
    const fetchData = {
      slug: "zoltyslon",
      debug: true,
      source: 3,
    };
  
    axios.post(process.env.REACT_APP_API_URL+'/restaurant', fetchData)
      .then(response => {
        const logo = response.data.logo;
        setLogoUrl(`https://haproxy.abspos.pl/media/${logo}`);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  


  return (
    <div className="zoltyslon">
      {logoUrl && <img src={logoUrl} alt="" className="" />}
    </div>
  );
  

}




export const MenuList = () => {
    const [menuData, setMenuData] = useState(null);
  
    useEffect(() => {
      const fetchData = {
        slug: "azhir",
        debug: true,
        source: 3,
      };
  
      axios.post(process.env.REACT_APP_API_URL+'/restaurant', fetchData)
        .then(response => {
          const apiData = response.data;
          const categories = apiData.category;
  
          const categoryNamesAndProductSlugs = categories.map(category => ({
            categoryName: category.name[0].text,
            productSlugs: category.products.map(product => product.slug.toString()),
          }));
  
          setMenuData(categoryNamesAndProductSlugs);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []);
  
    return (
      <div>
       
        {menuData && (
          <div className="menu-list">
            {menuData.map((category, index) => (
              <div key={index} className="category">
                <strong className="categoryName">
                  {category.categoryName}
                </strong>
                <div className="dania-list"> 
                  {category.productSlugs.map((slug, slugIndex) => (
                    <div key={slugIndex} className="dania"> 
                      {slug}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }




export const MenuProductComponent = () => {


    return (
      <div>
           
          <span style = {{marginLeft: "10%" ,display:"center",fontFamily: "Poppins", fontSize: "28px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal"}}>Program lojalnościowy</span>
          <div>
            <Menu></Menu>
          <Grid item xs={5} sm={5} className='punkty-info' style={{ display: 'flex', marginLeft: 'auto', marginTop: '20px',marginRight: '15px' }}>
                <Grid container direction="column">
                <Grid item xs={3} sm={3} textAlign={'center'} style={{ paddingLeft: '2px', marginTop: '4px' }}><span>Masz punktów</span></Grid>
                <Grid item xs={9} sm={9} textAlign={'center'} style={{ paddingRight: '10px', marginTop: '4px' }}><div className='element-punkt'>15 pkt<span className='pkt'></span></div></Grid>
                </Grid>
               
            </Grid>

            

         
          </div>
          <MenuList></MenuList>
      
         
      </div>
    );
  };